import * as React from 'react';
import styled from 'styled-components';
// @ts-ignore
import imgSrc from '../images/undraw_Choose.svg';
import {CenterSectionCSS, Media, Palette} from "../constants";
import {Button} from "primereact/button";
import {Link} from "../components/Link";
import {SectionImage} from "../components/SectionImage";

export function Hero() {
    return <Container>
        <Main>
            <HeroImage src={imgSrc} variant={"hero"}/>
            <Intro>
                <PreTextA>Sometimes...</PreTextA>
                <TextA>We All Need A Buddy</TextA>
                <TextB>...to help us make a better choice</TextB>
              <TextC>WanaBuddy helps internet addicts in the moment of temptation</TextC>
              <TextC>Compassionately offering alternatives to their addiction</TextC>
            </Intro>
        </Main>
        <Continue>
            <Link to={"/#mission"}>
                <Button label="Find Out More" icon="pi pi-chevron-down" className={'p-button-secondary'}/>
            </Link>
        </Continue>
    </Container>;
}

const Container = styled.section`
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #${Palette.MaximumYellowRed.hex};
  padding: 2rem 0;
  min-height: 85vh;
  justify-content: space-between;

  @media (${Media.tablet}) {
    height: 98vh;
    justify-content: space-around;
  }
`;

const HeroImage = styled(SectionImage)`
  display: none;
  @media (${Media.tablet}) {
    display: block;
    width: 35vw;
  }
`;

const Main = styled.div`
  ${CenterSectionCSS};
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  @media (${Media.tablet}) {
    flex-direction: row;    
  }
`;


const Intro = styled.div`
  @media (${Media.tablet}) {
    width: 55vw;
  }
  @media (${Media.desktop}) {
  }
  
`;

const PreTextA = styled.div`
  font-size: 6vw;
  margin-bottom: 1.5rem;
  @media (${Media.tablet}) {
    font-size: 3vw;
  }
  @media (${Media.laptop}) {
    font-weight: bold;
    font-size: 2rem;
  }
`;
const TextA = styled.div`
  font-size: 8.4vw;
  margin-bottom: 1.5rem;
  font-weight: bold;
  @media (${Media.tablet}) {
    font-size: 4vw;
  }
  @media (${Media.laptop}) {  
      font-size: 6rem;
  }
`;
const TextB = styled.div`
  font-size: 6vw;
  margin-bottom: 4rem;

  @media (${Media.tablet}) {
    font-size: 3vw;
  }
  @media (${Media.laptop}) {  
    font-size: 2rem;
    font-weight: bold;
  }
`;
const TextC = styled.div`
  font-size: 5.3vw;
  margin-bottom: 1.2rem;

  @media (${Media.tablet}) {
    font-size: 2vw;
  }
  @media (${Media.laptop}) {  
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
`;
const TextD = styled.div`
    @media (${Media.tablet}) {
      font-size: 1.3rem;
      line-height: 1.6rem;
      margin-bottom: 0.8rem;
      font-style: italic;
    }
`;


const Continue = styled.div`
  flex: 0 0 auto;
  @media (${Media.tablet}) {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
  }
`;
