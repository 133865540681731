import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
import {Hero} from "../sections/Hero";
import {Sections} from "../sections";
// @ts-ignore
import SocialImage from "../images/social.png";
import {PageProps} from "gatsby";


export default function Index(props: PageProps) {
    return <Layout {...props}>
        <div id={"home"}/>
        <Hero />
        {Sections.map(({Component, ...props}, i) => (<Component key={i} {...props} variant={i % 2 ? 'right' : 'left'}/>))}
    </Layout>
}
